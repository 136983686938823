import React from "react"
import { Row, Col, Container } from 'react-bootstrap/'
import { FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Image from "../components/image"
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components'
export default () => {
  return (
    <StaticQuery
      query={graphql`
      query MediaQuery {
        allMediaYaml {
          edges {
            node {
              description
              alt
              image {
                childImageSharp {
                  fluid (quality:100, maxWidth:1200) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
      
      `}
      render={data => {
        const settings = {
          className: 'media-slider',
          infinite: true,
          slidesToShow: 1,
          speed: 500,
          arrows: true,
          dots: true,
          swipeToSlide: true,
          autoplay: true,
          autoplaySpeed: 3000,
        };
        const SliderImage = styled.div`

      `;
        const SliderImageTitle = styled.h4`
        margin-top:2rem;
        text-align:-webkit-center;
        font-size:14px
      `;

        return (
          <Container fluid style={{ background: 'white' }}>
            <Row >
              <Col xs lg="6" style={{ alignSelf: 'center', marginBottom: '0rem', marginTop: '1rem' }} className='head-col-1' data-aos="fade-right">
                <h2><FormattedMessage id="media_retmes_h1" /><b><FormattedMessage id="media_events_h1" />

                </b></h2>
                <p><FormattedMessage id="media_desc_p" /> <br />
                  <FormattedMessage id="media_desc_p_line_2" /></p>
              </Col>
              <Col xs lg="4"  >
                <Slider {...settings}>
                  {data.allMediaYaml.edges.map(({ node }) => (
                    <div>
                      <Img fluid={node.image.childImageSharp.fluid} alt={node.alt} />
                      <SliderImageTitle>{node.description}</SliderImageTitle>
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Container >
        )
      }}
    />
  )
}