import React from "react"
import { Row, Col, Card } from 'react-bootstrap/'
import PropTypes from "prop-types"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import retmesMms from "../images/MMS.svg"
import retmesPms from "../images/PMS.svg"
import retmesOee from "../images/OEE.svg"
import retmesMlq from "../images/mIQ.svg"
import { FaExchangeAlt } from "react-icons/fa";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
class SolutionsSlider extends React.Component {

  render() {

    const { classNames } = this.props
    SolutionsSlider.propTypes = {
      classNames: PropTypes.string,
    }

    const settings = {
      dots: true,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
    };
    return (
      <>
        <div className={'solutions-box' + classNames}>
          <Col sm style={{ alignSelf: 'center', marginBottom: '3rem', marginTop: '4em' }} className='head-col-1'  >
            <h2><FormattedMessage id="lp_content_solutions_title" /></h2>
            <p><FormattedMessage id="lp_content_solutions_desc" /> <br />
              <FormattedMessage id="lp_content_solutions_desc_line_2" />
            </p>
          </Col>
          <Row className='solutions-card-row'>
            <Col className='solution-col' sm data-aos-delay="300">
              <Link className='solutions-card-link' to='/cozumler/uretim-izleme-sistemi'>
                <Card className='solutions-card'>
                  <Card.Body className='solutions-card-body-mms'>
                    <Card.Title className='solutions-card-title-image'><img src={retmesMms} alt="" /></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted"><FormattedMessage id="retmes-mms-box-title" /></Card.Subtitle>
                    <Card.Text>
                      <FormattedMessage id="retmes-mms-box-desc" />
                    </Card.Text>
                  </Card.Body>
                  <div className='solutions-card-footer-mms'>
                    <p className='more-detail'><FormattedMessage id="box-details" /></p>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col className='solution-col' sm data-aos-delay="450">
              <Link className='solutions-card-link' to='/cozumler/enerji-yonetim-sistemi'>
                <Card className='solutions-card'>
                  <Card.Body className='solutions-card-body-pms'>
                    <Card.Title className='solutions-card-title-image'><img src={retmesPms} alt="" /></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted"><FormattedMessage id="retmes-pms-box-title" /></Card.Subtitle>
                    <Card.Text>
                      <FormattedMessage id="retmes-pms-box-desc" />
                    </Card.Text>
                  </Card.Body>
                  <div className='solutions-card-footer-pms'>
                    <p className='more-detail'><FormattedMessage id="box-details" /></p>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col className='solution-col' sm data-aos-delay="600">
              <Link className='solutions-card-link' to='/cozumler/toplam-ekipman-verimliligi'>
                <Card className='solutions-card'>
                  <Card.Body className='solutions-card-body-oee'>
                    <Card.Title className='solutions-card-title-image'><img src={retmesOee} alt="" /></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted"><FormattedMessage id="retmes-oee-box-title" /></Card.Subtitle>
                    <Card.Text style={{ marginTop: '50px' }}>
                      <FormattedMessage id="retmes-oee-box-desc" />
                    </Card.Text>
                  </Card.Body>
                  <div className='solutions-card-footer-oee'>
                    <p className='more-detail'><FormattedMessage id="box-details" /></p>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col className='solution-col' sm data-aos-delay="750">
              <Link className='solutions-card-link' to='/cozumler/yapay-zeka-destekli-uretim'>
                <Card className='solutions-card'>
                  <Card.Body className='solutions-card-body-mlq'>
                    <Card.Title className='solutions-card-title-image'><img src={retmesMlq} alt="" /></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted"><FormattedMessage id="retmes-mlq-box-title" /></Card.Subtitle>
                    <Card.Text style={{ marginTop: '50px' }}>
                      <FormattedMessage id="retmes-mlq-box-desc" />
                    </Card.Text>
                  </Card.Body>
                  <div className='solutions-card-footer-mlq'>
                    <p className='more-detail'><FormattedMessage id="box-details" /></p>
                  </div>
                </Card>
              </Link>
            </Col>
            <div style={{ marginTop: '3rem' }} className='retmes-detail-button'> <Link to='/cozumler' style={{ color: 'white' }}> <FaExchangeAlt /> <FormattedMessage id="compare" /> </Link> </div>
          </Row>
        </div>
        <Col className='solution-col' sm style={{ alignSelf: 'center', marginBottom: '3rem' }} className={'head-col-1-solutions' + classNames}  >
          <h2><FormattedMessage id="lp_content_solutions_title" /></h2>
          <p><FormattedMessage id="lp_content_solutions_desc" /> <br />
            <FormattedMessage id="lp_content_solutions_desc_line_2" />
          </p>
        </Col>
        <Slider {...settings} className={classNames || 'desktopslider'} >
          <Col className='solution-col' sm data-aos-delay="300">
            <Link className='solutions-card-link' to='/cozumler/uretim-izleme-sistemi'>
              <Card className='solutions-card'>
                <Card.Body className='solutions-card-body-mms'>
                  <Card.Title className='solutions-card-title-image'><img src={retmesMms} alt="" /></Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"><FormattedMessage id="retmes-mms-box-title" /></Card.Subtitle>
                  <Card.Text>
                    <FormattedMessage id="retmes-mms-box-desc" />
                  </Card.Text>
                </Card.Body>
                <div className='solutions-card-footer-mms'>
                  <p className='more-detail'><FormattedMessage id="box-details" /></p>
                </div>
              </Card>
            </Link>
          </Col>

          <Col className='solution-col' sm data-aos-delay="450">
            <Link className='solutions-card-link' to='/cozumler/enerji-yonetim-sistemi'>
              <Card className='solutions-card'>
                <Card.Body className='solutions-card-body-pms'>
                  <Card.Title className='solutions-card-title-image'><img src={retmesPms} alt="" /></Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"><FormattedMessage id="retmes-pms-box-title" /></Card.Subtitle>
                  <Card.Text>
                    <FormattedMessage id="retmes-pms-box-desc" />
                  </Card.Text>
                </Card.Body>
                <div className='solutions-card-footer-pms'>
                  <p className='more-detail'><FormattedMessage id="box-details" /></p>
                </div>
              </Card>
            </Link>
          </Col>

          <Col className='solution-col' sm data-aos-delay="600">
            <Link className='solutions-card-link' to='/cozumler/toplam-ekipman-verimliligi'>
              <Card className='solutions-card'>
                <Card.Body className='solutions-card-body-oee'>
                  <Card.Title className='solutions-card-title-image'><img src={retmesOee} alt="" /></Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"><FormattedMessage id="retmes-oee-box-title" /></Card.Subtitle>
                  <Card.Text style={{ marginTop: '50px' }}>
                    <FormattedMessage id="retmes-oee-box-desc" />
                  </Card.Text>
                </Card.Body>
                <div className='solutions-card-footer-oee'>
                  <p className='more-detail'><FormattedMessage id="box-details" /></p>
                </div>
              </Card>
            </Link>
          </Col>
          <Col className='solution-col' sm data-aos-delay="750">
            <Link className='solutions-card-link' to='/cozumler/yapay-zeka-destekli-uretim'>
              <Card className='solutions-card'>
                <Card.Body className='solutions-card-body-mlq'>
                  <Card.Title className='solutions-card-title-image'><img src={retmesMlq} alt="" /></Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"><FormattedMessage id="retmes-mlq-box-title" /></Card.Subtitle>
                  <Card.Text style={{ marginTop: '50px' }}>
                    <FormattedMessage id="retmes-mlq-box-desc" />
                  </Card.Text>
                </Card.Body>
                <div className='solutions-card-footer-mlq'>
                  <p className='more-detail'><FormattedMessage id="box-details" /></p>
                </div>
              </Card>
            </Link>
          </Col>

        </Slider>
      </>
    );
  }
}

export default injectIntl(SolutionsSlider)
