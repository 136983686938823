import React from "react"
import Layout from "../components/layout"
import CookieConsent from "react-cookie-consent";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import { Row, Container, Col, Card } from 'react-bootstrap/'
import RetmesAILogo from "../images/retmes-ai/retmes-ai-logo.svg"
// import RetmesAILogoDefault from "../images/retmes-ai/retmes-ai-logo-default.svg"
import retmesHeadwhite from "../images/retmes-head-white.svg"
import Tilt from 'react-tilt'
import SolutionsSlider from "../components/solutions-slider";
import RetmesERP from "../images/retmes-lp-erp.png"
import RetmesMap from "../images/retmes-map.svg"
import RetmesLocation from "../images/retmes-location-selected.svg"
import RetmesLocationdefault from "../images/retmes-location-default.svg"
import RetmesKeys from "../images/keys.svg"
import RetmesCloud from "../images/cloud.svg"
import RetmesThirdparty from "../images/thirdparty.svg"
import RetmesSpecial from "../images/thirdparty.svg"
import RetmesPartner from "../images/partner.svg"
import RetmesSpeed from "../images/speed.svg"
import RetmesAi from "../images/yapayzeka.svg"
import RetmesLocal from "../images/local.svg"
import Media from "../components/media"
import References from "../components/references"
import Arayuz from "../images/arayuz.png"
import Wave from 'react-wavify'

class IndexPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      innerWidth: 0
    }
    this.state = {
      UrlQuery: "",
    }
  }

  componentDidMount() {
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
    this.setState({ UrlQuery: window.location.pathname })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.setState({ isMobile: window.innerWidth < 1200 })
  }

  render() {
    const mobilView = this.state.isMobile ? 'mobile' : '';
    const { intl } = this.props
    const { UrlQuery } = this.state
    const retmeslogoIcon = "https://res.cloudinary.com/konzek/image/upload/v1603710628/Retmes/logo/SVG/logo/retmes-logo-default.svg"
    return (<Layout>
      <SEO
        title="Retmes"
        description="Retmes, Endüstri 4.0'daki Yapay Zeka Destekli MES/MOM, Üretim Takip ve Üretim İzleme Sistemlerini, makine öğrenimiyle yöneten entegre sistem çözümüdür."
      />
      <Row className='home-content' style={{ marginTop: '200px' }} >
        <Container >
          <div className='top-header-main'>
            <Link to='/'> <img src={retmeslogoIcon} alt="retmes logo" /></Link>
          </div>
          <Row className='home-headline'>
            <Col sm className='retmes-landing-page-col lp-col' >
              {UrlQuery.includes("/en") ?
                <>
                  <h1 style={{ fontWeight: 'bold', fontSize: '2.7rem', display: 'inline', marginRight: '0.5rem' }}>
                    <FormattedMessage id="Landing_Page_Mes" />
                  </h1>
                  <strong style={{ fontWeight: 'bold', fontSize: '2.7rem' }}><FormattedMessage id="Landing_Page_Highlight_2" /></strong>
                </>
                :
                <>
                  <strong style={{ fontWeight: 'bold', fontSize: '2.7rem' }}><FormattedMessage id="Landing_Page_Highlight_2" /></strong>
                  <h1 style={{ fontWeight: 'bold', fontSize: '2.7rem', display: 'inline', marginLeft: '0.5rem' }}>
                    <FormattedMessage id="Landing_Page_Mes" />
                  </h1>
                </>
              }
              <br />
              <h2 className='hero-subtitle'>
                <FormattedMessage id="Landing_Page_Highlight_3" /></h2>
              <br />
              <div className='button-group'>
                <div className='retmes-call-2-button'>
                  <Link className='call2link' to='/iletisim'>
                    <FormattedMessage id="Contact_to_us" />
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm className='retmes-landing-page-col lp-col' >

              <img src={Arayuz} className='lp-aray' alt='retmes arayüz' />

            </Col>
          </Row>
          <span className="scroll-btn">
            <span class="mouse">
              <span>
              </span>
            </span>
          </span>
        </Container>
        {/* <Wave fill='white'
        paused={false}
        className='background-wave'
        options={{
          height: 15,
          amplitude: 15,
          speed: 0.3,
          points: 5
        }}
      /> */}
      </Row>

      <Container className='home-row-2-container' >
        <Col sm style={{ alignSelf: 'center', marginTop: '3rem' }} className='head-col-first'  >
          <Row>
            <Col>
              <img style={{ width: '700px' }} src={RetmesERP} alt="Retmes Üretim Yürütme ve İzleme Sistemi " />
            </Col>
          </Row>
          <h2><FormattedMessage id="lp_content_mes-title" /></h2>
          <p><FormattedMessage id="lp_content_mes-desc" /></p><br />
          <Link to='/mes'> <div className='retmes-detail-button'><FormattedMessage id="whatsmes" /></div></Link>
        </Col>
        <SolutionsSlider
          classNames={mobilView}
        />
      </Container>
      <svg viewBox="0 0 1440 120" id="wave">
        <path fill="white" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
      </svg>
      <div className='landing-page-content-divider'>
        <Container>
          <Row>
            <Col sm style={{ alignSelf: 'center', marginBottom: '3rem', marginTop: '0rem', textAlign: '-webkit-left' }} className='head-col-1'  >
              <h2><FormattedMessage id="lp_tools_section_title_h1" /></h2>
              <p> <FormattedMessage id="lp_tools_section_desc_p" /></p>
            </Col>
          </Row>
          <Row className='home-row-2'  >
            <Col sm className='retmes-lp-ml-col'>
              <div className='anomali1' />
            </Col>
            <Col sm className='retmes-lp-ml-col-2' >
              <h2><FormattedMessage id="lp_tools_section_tool_1_title_h1" /></h2>
              <p><FormattedMessage id="lp_tools_section_tool_1_desc_p" /></p>
            </Col>
          </Row>
          <Row className='home-row-2'>
            <Col sm className='retmes-lp-ml-col-2' >
              <h2><FormattedMessage id="lp_tools_section_tool_2_title_h1" /> </h2>
              <p><FormattedMessage id="lp_tools_section_tool_2_desc_p" /></p>
            </Col>
            <Col sm className='retmes-lp-ml-col'>
              <div className='bar1' />
            </Col>
          </Row>
          <Row className='home-row-2' style={{ flexWrap: 'wrap-reverse' }}>
            <Col sm className='retmes-lp-ml-col'>
              <div className='kestirimci1'>
              </div>
            </Col>
            <Col sm className='retmes-lp-ml-col-2' >
              <h2><FormattedMessage id="lp_tools_section_tool_3_title_h1" /> </h2>
              <p><FormattedMessage id="lp_tools_section_tool_3_desc_p" /> </p>
            </Col>
          </Row>
          <Row className='home-row-2' style={{ paddingBottom: '0rem' }}  >
            <Col sm className='retmes-lp-ml-col-2' >
              <h2><FormattedMessage id="lp_tools_section_tool_4_title_h1" /></h2>
              <p><FormattedMessage id="lp_tools_section_tool_4_desc_p" /> </p>
            </Col>
            <Col sm className='retmes-lp-ml-col'>
              <div className='proses1'> </div>
            </Col>
          </Row>
        </Container>

      </div>
      <Container>
        <Row>
          <Col sm style={{ alignSelf: 'right', marginBottom: '0rem', marginTop: '1rem', textAlign: 'right' }} className='head-col-1'  >
            <h2><FormattedMessage id="lp_monitoring_title_h1" /></h2>
            <p className='retmes-map-desc-p'><FormattedMessage id="lp_monitoring_desc_p" /></p>
          </Col>
        </Row>
        <Row>
          <figure className='retmes-map-figure' style={{ position: 'relative' }}>
            <img className='retmes-map-image' src={RetmesMap} alt="retmes map" />
            <div className='retmes-map-location-usa'>
              <img className='retmes-pin' src={RetmesLocationdefault} alt="retmes location default usa" />
              <div className='retmes-pin-bubble'>
                <div className='retmes-pin-bubble-name'><p><FormattedMessage id="lp_monitoring_bubble_title_1" /></p></div>
                <div className='retmes-pin-bubble-desc'><p><FormattedMessage id="lp_monitoring_bubble_desc_1" /></p></div>
              </div>
            </div>
            <div className='retmes-map-location-africa'>
              <img className='retmes-pin' src={RetmesLocationdefault} alt="retmes location default africa" />
              <div className='retmes-pin-bubble'>
                <div className='retmes-pin-bubble-name'><p><FormattedMessage id="lp_monitoring_bubble_title_1" /></p></div>
                <div className='retmes-pin-bubble-desc'><p><FormattedMessage id="lp_monitoring_bubble_desc_1" /></p></div>
              </div>
            </div>
            <div className='retmes-map-location-asia'>
              <img className='retmes-pin' src={RetmesLocationdefault} alt="retmes location default asia" />
              <div className='retmes-pin-bubble'>
                <div className='retmes-pin-bubble-name'><p><FormattedMessage id="lp_monitoring_bubble_title_2" /></p></div>
                <div className='retmes-pin-bubble-desc'><p><FormattedMessage id="lp_monitoring_bubble_desc_1" /></p></div>
              </div>
            </div>
            <div className='retmes-map-location-eur'>
              <img className='retmes-pin' src={RetmesLocationdefault} alt="retmes location default eur" />
              <div className='retmes-pin-bubble'>
                <div className='retmes-pin-bubble-name'><p><FormattedMessage id="lp_monitoring_bubble_title_3" /></p></div>
                <div className='retmes-pin-bubble-desc'><p><FormattedMessage id="lp_monitoring_bubble_desc_1" /></p></div>
              </div>
            </div>
            <div className='retmes-map-location-tr'>
              <img className='retmes-pin' src={RetmesLocation} alt="retmes location tr" />
              <div className='retmes-pin-bubble-main'>
                <div className='retmes-pin-bubble-name'><p><FormattedMessage id="lp_monitoring_bubble_title_4" /></p></div>
                <div className='retmes-pin-bubble-desc'><p><FormattedMessage id="lp_monitoring_bubble_desc_2" /></p></div>
              </div>
            </div>
          </figure>

        </Row>
      </Container>


      <Container>
        <Row>
          <Col sm style={{ alignSelf: 'center', marginBottom: '0rem', marginTop: '1rem' }} className='head-col-1'  >
            <h2><FormattedMessage id="lp_why_us_h1" /></h2>
            <p><FormattedMessage id="lp_why_us_p" /><br />
              <FormattedMessage id="lp_why_us_p_line_2" /></p>
          </Col>
        </Row>
        <Row className='features-card-row'>
          <Card className='features-card'>
            <Card.Body className='features-card-body'>
              <img src={RetmesAi} style={{ width: '3rem', marginBottom: '18px' }} alt="" />
              <Card.Title as='h3' className='features-included-card-title'><FormattedMessage id="lp_why_us_box_8_h5" /></Card.Title>
              <Card.Text className='features-card-text'>
                <h3><FormattedMessage id="lp_why_us_box_8_h3" /></h3>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='features-card'>
            <Card.Body className='features-card-body'>
              <img src={RetmesSpeed} style={{ width: '3rem', marginBottom: '18px' }} alt="" />
              <Card.Title as='h3' className='features-included-card-title'><FormattedMessage id="lp_why_us_box_2_h5" /></Card.Title>
              <Card.Text className='features-card-text'>
                <h3><FormattedMessage id="lp_why_us_box_2_h3" /></h3>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='features-card'>
            <Card.Body className='features-card-body'>
              <img src={RetmesCloud} style={{ width: '3rem', marginBottom: '18px' }} alt="" />
              <Card.Title as='h3' className='features-included-card-title'><FormattedMessage id="lp_why_us_box_3_h5" /></Card.Title>
              <Card.Text className='features-card-text'>
                <h3><FormattedMessage id="lp_why_us_box_3_h3" /></h3>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='features-card'>
            <Card.Body className='features-card-body'>
              <img src={RetmesThirdparty} style={{ width: '3rem', marginBottom: '18px' }} alt="" />
              <Card.Title as='h3' className='features-included-card-title'><FormattedMessage id="lp_why_us_box_4_h5" /></Card.Title>
              <Card.Text className='features-card-text'>
                <h3><FormattedMessage id="lp_why_us_box_4_h3" /></h3>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
        <Row className='features-card-row' style={{ paddingBottom: '40px' }}>
          <Card className='features-card'>
            <Card.Body className='features-card-body'>
              <img src={RetmesPartner} style={{ width: '3rem', marginBottom: '18px' }} alt="" />
              <Card.Title as='h3' className='features-included-card-title'><FormattedMessage id="lp_why_us_box_5_h5" /></Card.Title>
              <Card.Text className='features-card-text'>
                <h3><FormattedMessage id="lp_why_us_box_5_h3" /></h3>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='features-card'>
            <Card.Body className='features-card-body'>
              <img src={RetmesSpecial} style={{ width: '3rem', marginBottom: '18px' }} alt="" />
              <Card.Title as='h3' className='features-included-card-title'><FormattedMessage id="lp_why_us_box_6_h5" /></Card.Title>
              <Card.Text className='features-card-text'>
                <h3><FormattedMessage id="lp_why_us_box_6_h3" /></h3>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='features-card'>
            <Card.Body className='features-card-body'>
              <img src={RetmesLocal} style={{ width: '6rem', marginBottom: '18px' }} alt="" />
              <Card.Title as='h3' className='features-included-card-title'><FormattedMessage id="lp_why_us_box_7_h5" /></Card.Title>
              <Card.Text className='features-card-text'>
                <h3><FormattedMessage id="lp_why_us_box_7_h3" /></h3>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='features-card'>
            <Card.Body className='features-card-body'>
              <img src={RetmesKeys} style={{ width: '2rem', marginBottom: '18px' }} alt="" />
              <Card.Title as='h3' className='features-included-card-title'><FormattedMessage id="lp_why_us_box_1_h5" /></Card.Title>
              <Card.Text className='features-card-text'>
                <h3> <FormattedMessage id="lp_why_us_box_1_h3" /></h3>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>
      <svg viewBox="0 0 1440 120" id="wave"><path fill="white" d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path></svg>
      <Media />
      <Container>
        <References />
        <CookieConsent
          buttonStyle={{ background: "#3bc6f4", fontSize: "13px", color: 'white', borderRadius: '5px' }}
          buttonText={<FormattedMessage id="cookie_consent_button_text" />}>
          <FormattedMessage id="cookie_consent_desc" />
        </CookieConsent>
      </Container>
      <svg viewBox="0 0 1920 110" className='footer-svg'>
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stop-color="black" />
            <stop offset="100%" stop-color="black" />
          </linearGradient>
        </defs><g data-name="Layer 2"><g data-name="Layer 1"><path d="M0 90.51a1428.76 1428.76 0 0 1 161.57-51.15C670.24-88.92 969.21 140.79 1525.6 102c87.48-6.14 231.93-22.36 411.12-75.13l-4 204.28c0 .29-.3.29-.4.48q-558.7-23-1117.2-19.38Q407.55 214.87 0 231.65V90.51z" fill="url(#gradient)" data-name="Layer 1-2" /></g></g></svg>
    </Layout >
    );

  }

}

export default injectIntl(IndexPage)
