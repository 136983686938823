import React from "react"
import { Row, Col, Container } from 'react-bootstrap/'
import { FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
export default () => {
  return (
    <StaticQuery
      query={graphql`
      query RefsQuery {
        allReferencesYaml {
          edges {
            node {
              id
              alt
              width
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      
      `}
      render={data => {
        return (
          <Row>
            <Col
              className='head-col-last'
              sm
              style={{
                alignSelf: 'center',
                width: '100%',
                overflow: 'hidden',
                padding: '1.25rem 0px'
              }}>
              <h2><FormattedMessage id="lp_who_uses_h1" /></h2>
              <div className='users-logos-col'>
                {data.allReferencesYaml.edges.map(({ node }) => (
                  <Img
                    style={{ width:`${node.width || '5rem'}`}}
                    className='users-logos'
                    fluid={node.image.childImageSharp.fluid} alt={node.alt} key={node.id} />
                ))}
              </div>
            </Col>
          </Row>
        )
      }}
    />
  )
}